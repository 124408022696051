import React, { useEffect } from "react";
import Modal from "react-modal";
import { useFileList } from "shared/modules/capitalmind/hooks/file-list";
import FileComponent from "./file-component";
import { styled } from "styled-components";

const StyledText = styled.span`
  color: #4b5466;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -33%)",
    width: "80%",
  },
};

function FileModal({ isModalOpen, closeModal }: Props): JSX.Element {
  const { files, refreshFileList } = useFileList({ source: "demo" });

  useEffect(() => {
    if (isModalOpen) {
      refreshFileList();
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {files?.length > 0 ? (
        <div>
          {files.map((file) => (
            <FileComponent file={file} key={file?._id} />
          ))}
        </div>
      ) : (
        <StyledContainer>
          <StyledText>
            There are no context available. Please upload files to add context.
          </StyledText>
        </StyledContainer>
      )}
    </Modal>
  );
}

export default FileModal;
