import React from "react";
import styled from "styled-components";
import DmiBanner1 from "theme/assets/images/dmi-banner-1.png";
import DmiBanner2 from "theme/assets/images/dmi-banner-2.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 24px 40px; */
  justify-content: end;
  align-items: end;
  border-radius: 24px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: column;
  max-width: 100%;
`;
const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledImage = styled.img`
  width: 100%;
`;

const StyledImageRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

function Placeholder(): JSX.Element {
  return (
    <Container>
      <StyledWrapper>
        <StyledHeaderContainer>
          <StyledImage src={DmiBanner1} alt="CM" />
          <StyledImage src={DmiBanner2} alt="CM" />
        </StyledHeaderContainer>
        <StyledImagesContainer>
          <StyledImageRowContainer></StyledImageRowContainer>
        </StyledImagesContainer>
      </StyledWrapper>
    </Container>
  );
}

export default Placeholder;
