import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 8px 28px;
  flex-direction: column;
  gap: 10px;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #4b5466;
`;

const StyledTextUser = styled.span`
  color: black;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledTextLogo = styled.span`
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledImageContainer = styled.div`
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  border: 1px solid red;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ChatBoxUser({ text }: { text: string }): JSX.Element {
  return (
    <StyledContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <StyledImageContainer>
          <StyledTextLogo>Y</StyledTextLogo>
        </StyledImageContainer>
        <StyledTextUser>You</StyledTextUser>
      </div>
      <StyledUserChatBox>
        <StyledText>{text}</StyledText>
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default ChatBoxUser;
