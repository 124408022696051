import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../routes";
import { QueryClientProvider } from "react-query";
import { GlobalStateContext, InitialState } from "shared/global-state";
import queryClient from "shared/network";
import { Worker } from "@react-pdf-viewer/core";

import "react-toastify/dist/ReactToastify.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function App(): JSX.Element {
  const [globalState, setGlobalState] = useState(InitialState);
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <React.StrictMode>
        <GlobalStateContext.Provider
          value={{
            state: globalState,
            setState: setGlobalState,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </QueryClientProvider>
        </GlobalStateContext.Provider>
      </React.StrictMode>
    </Worker>
  );
}

export default App;
